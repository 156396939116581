import {
  FetchCompanySmartContractSettingsCacheKey,
  FetchCompanySmartContractSettingsFilters,
  FetchCompanySmartContractSettingsLimit,
  FetchCompanySmartContractSettingsPage,
  FetchCompanySmartContractSettingsSort,
  FetchCompanySmartContractSettingsGqlQuery
} from '../../companySmartContractSettingsTypes';
import { IndexQueryDefaultOptionsOpts } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_COMPANY_SMART_CONTRACT_SETTING_FILTERS,
  INITIAL_COMPANY_SMART_CONTRACT_SETTING_LIMIT,
  INITIAL_COMPANY_SMART_CONTRACT_SETTING_PAGE,
  INITIAL_COMPANY_SMART_CONTRACT_SETTING_SORT
} from '../../companySmartContractSettingsConstants';

interface PaginatedCompanySmartContractSettingsOptions<
  FetchCompanySmartContractSettingsCompanySmartContractSettingType
> {
  cacheKey: FetchCompanySmartContractSettingsCacheKey;
  initialFilters?: FetchCompanySmartContractSettingsFilters;
  initialSort?: FetchCompanySmartContractSettingsSort;
  initialPage?: FetchCompanySmartContractSettingsPage;
  initialLimit?: FetchCompanySmartContractSettingsLimit;
  options?: IndexQueryDefaultOptionsOpts<FetchCompanySmartContractSettingsCompanySmartContractSettingType>;
  query: FetchCompanySmartContractSettingsGqlQuery;
}

const scope = 'companySmartContractSettings';

function usePaginatedCompanySmartContractSettings<
  FetchCompanySmartContractSettingsCompanySmartContractSettingType
>({
  cacheKey,
  initialFilters = INITIAL_COMPANY_SMART_CONTRACT_SETTING_FILTERS,
  initialSort = INITIAL_COMPANY_SMART_CONTRACT_SETTING_SORT,
  initialPage = INITIAL_COMPANY_SMART_CONTRACT_SETTING_PAGE,
  initialLimit = INITIAL_COMPANY_SMART_CONTRACT_SETTING_LIMIT,
  options = {},
  query
}: PaginatedCompanySmartContractSettingsOptions<FetchCompanySmartContractSettingsCompanySmartContractSettingType>) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchCompanySmartContractSettingsCompanySmartContractSettingType>(
    {
      cacheKey,
      scope,
      initialFilters,
      initialLimit,
      initialPage,
      initialSort,
      options,
      query
    }
  );

  return {
    companySmartContractSettingsData: data,
    companySmartContractSettings: items,
    companySmartContractSettingsError: itemsError,
    companySmartContractSettingsErrorMessage: itemsErrorMessage,
    companySmartContractSettingsTotalCount: itemsTotalCount,
    companySmartContractSettingsFetched: isFetched,
    companySmartContractSettingsLoading: isLoading,
    companySmartContractSettingsIsPlaceholderData: isPlaceholderData,
    companySmartContractSettingsFilters: currentFilters,
    companySmartContractSettingsSort: currentSort,
    companySmartContractSettingsPage: currentPage,
    companySmartContractSettingsLimit: currentLimit,
    updateCompanySmartContractSettingCache: updateItemCache,
    filterCompanySmartContractSettings: filterItems,
    changeCompanySmartContractSettingsFilters: changeItemsFilters,
    clearCompanySmartContractSettingsFilters: clearItemsFilters,
    sortCompanySmartContractSettings: sortItems,
    paginateCompanySmartContractSettings: paginateItems,
    limitCompanySmartContractSettings: limitItems,
    prefetchCompanySmartContractSettings: prefetchItems
  };
}

export default usePaginatedCompanySmartContractSettings;
