import React, { ReactNode, useState } from 'react';
import size from 'lodash/size';
import map from 'lodash/map';

import {
  ClassName,
  I18nText,
  IsDisabled,
  IsFetched
} from '../../../../../types';
import { UserID } from '../../../usersTypes';
import { UsersSimpleModalButtonUserItem } from './UsersSimpleModalButton.types';

import { UsersSimpleModalButtonUser } from './components/UsersSimpleModalButtonUser';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';
import { Tooltip } from '../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { Translate } from '../../../../../helpers/Translate';

import { words } from '../../../../../locales/keys';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

interface UsersSimpleModalButtonProps {
  buttonChildren: ReactNode;
  disabled?: IsDisabled;
  i18nModalTitle: I18nText;
  onRemoveUser?: (id: UserID) => void;
  onRemoveAllUsers?: () => Promise<unknown>;
  users: UsersSimpleModalButtonUserItem[];
  usersFetched?: IsFetched;
  visibleUsersLimit?: number;
  withFinanceRole?: boolean;
  className?: ClassName;
}

function UsersSimpleModalButton({
  buttonChildren,
  disabled,
  i18nModalTitle,
  onRemoveUser,
  onRemoveAllUsers,
  users,
  usersFetched,
  visibleUsersLimit = 3,
  withFinanceRole = false,
  className
}: UsersSimpleModalButtonProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const tooltipUserNamesAsString = users
    .slice(0, visibleUsersLimit)
    .map(({ fullName }) => fullName)
    .join(', ');

  const isMoreUsersTextVisible = size(users) > visibleUsersLimit;

  const moreUsersCount = size(users) - visibleUsersLimit;

  const taskTeam = (
    <LoadingSkeleton loaded={usersFetched}>
      <div className="p-4">
        <ul>
          {map(users, (user: UsersSimpleModalButtonUserItem) => (
            <UsersSimpleModalButtonUser
              key={user.nanoId}
              onRemoveUser={onRemoveUser}
              user={user}
              withFinanceRole={withFinanceRole}
            />
          ))}
        </ul>
      </div>
    </LoadingSkeleton>
  );

  const tooltipContent = (
    <div className="text-center space-y-1.5">
      <div>
        {tooltipUserNamesAsString}{' '}
        {isMoreUsersTextVisible ? (
          <span className="inline-block">
            <Translate id={words.and} /> +{moreUsersCount}
          </span>
        ) : null}
      </div>
      {isMoreUsersTextVisible ? (
        <div className="font-medium">
          <SimpleModalButton
            i18nTitle={i18nModalTitle}
            withoutButtons
            modalSize="sm"
            childrenClassName="flex-1 overflow-y-auto px-2"
            i18nText={words.viewAllMembers}
          >
            {taskTeam}
          </SimpleModalButton>
        </div>
      ) : null}
    </div>
  );

  return (
    <div ref={setReferenceTooltipElement}>
      <SimpleModalButton
        className={
          className ||
          'group dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm'
        }
        disabled={disabled}
        i18nTitle={i18nModalTitle}
        withoutButtons={!onRemoveAllUsers}
        i18nSubmitText={words.removeAll}
        onSubmit={onRemoveAllUsers}
        submitButtonColor="gray"
        buttonsContainerClassName="flex p-4 space-x-1 justify-between"
        modalSize="sm"
        childrenClassName="flex-1 overflow-y-auto px-2"
        buttonChildren={buttonChildren}
      >
        {taskTeam}
      </SimpleModalButton>

      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={TooltipPlacement.BOTTOM}
        interactive
        withArrow
        appendTo={document.body}
        zIndex={0}
      >
        {tooltipContent}
      </Tooltip>
    </div>
  );
}

export default UsersSimpleModalButton;
