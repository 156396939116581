import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cl from 'classnames';

import { AvBillingInfoPaymentMethods } from '../../../../avBillingInfos/avBillingInfosTypes';
import { Currencies } from '../../../../../types';
import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormFields
} from './CreateProjectInTeamForm.types';
import { CreateProjectInTeamFormTasksListHandle } from './components/CreateProjectInTeamFormTasksList/CreateProjectInTeamFormTasksList';
import { TeamNanoIdSelectFieldTeamType } from '../../../../teams/helpers/TeamNanoIdSelectField/components/TeamNanoIdSelectFieldControl';

import { useCreateProjectInTeamFormContext } from './hooks/useCreateProjectInTeamFormContext';
import { usePreviousValue } from '../../../../../common/hooks/usePreviousValue';
import { useCreateProjectInTeamFormInvoiceDetails } from './components/CreateProjectInTeamFormInvoiceDetails/hooks/useCreateProjectInTeamFormInvoiceDetails';

import { CreateProjectInTeamFormButtons } from './components/CreateProjectInTeamFormButtons';
import { CreateProjectInTeamFormImplementationDateField } from './components/CreateProjectInTeamFormImplementationDateField';
import { CreateProjectInTeamFormInvoiceDetails } from './components/CreateProjectInTeamFormInvoiceDetails';
import { CreateProjectInTeamFormSplitPartsInput } from './components/CreateProjectInTeamFormSplitPartsInput';
import { CreateProjectInTeamFormTasksList } from './components/CreateProjectInTeamFormTasksList';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { CurrencySelectField } from '../../../../../helpers/FormFields/CurrencySelectField';
import { DropzoneField } from '../../../../../helpers/FormFields/DropzoneField';
import { ExchangeRatesListTooltipIconButton } from '../../../../currencyRates/helpers/ExchangeRatesListTooltipIconButton';
import { Form } from '../../../../../helpers/Form';
import { GeneralLedgersSelectField } from '../../../../../helpers/FormFields/GeneralLedgersSelectField';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TeamNanoIdSelectField } from '../../../../teams/helpers/TeamNanoIdSelectField';
import { TeamsUserIdSelectField } from '../../../../teamsUsers/helpers/TeamsUserIdSelectField';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import { Translate } from '../../../../../helpers/Translate';

import { TeamsUserCache } from '../../../../teamsUsers/TeamsUserCache';

import { ProjectsPermissions } from '../../../projectsConstants';
import { TasksPermissions } from '../../../../tasks/tasksConstants';
import { baseCurrenciesList } from '../../../../currencyRates/currencyRatesConstants';
import {
  attachmentsKeys,
  formsFields,
  invoicesKeys,
  projectsKeys,
  stringsKeys,
  teamsKeys,
  usersKeys,
  words
} from '../../../../../locales/keys';

const CREATE_PROJECT_IN_TEAM_FORM = 'create-project-in-team-form';

const initialTeamsFilters = { teamType: { eq: 'clients' } };

function CreateProjectInTeamForm() {
  const {
    control,
    createProjectInTeamLoading,
    currentUser,
    formNanoId,
    handleCreateOnlyProject,
    register,
    registerDescription,
    registerName,
    selectedTeamNanoId,
    setOwnerId,
    setPaymentMethod,
    setTerms,
    setCurrency,
    validationErrors,
    watchProjectHasItems,
    handleChangeCurrency,
    currencyExchangeRate,
    currencyPrefix,
    companyCurrency,
    watchSplitParts,
    setSplitPartValue,
    setFocusSplitPart
  } = useCreateProjectInTeamFormContext();

  const isTeamSelected = !!selectedTeamNanoId;

  const blockedStyles = useMemo(
    () => ({
      'opacity-50 pointer-events-none': !isTeamSelected
    }),
    [isTeamSelected]
  );

  const tasksListRef = useRef<CreateProjectInTeamFormTasksListHandle>();

  const handleTeamNanoIdAfterChange = useCallback<
    (team: TeamNanoIdSelectFieldTeamType) => void
  >(
    (team) => {
      setTerms(team.terms || '');
      setPaymentMethod(team.preferredPaymentMethod);
      setOwnerId(null);

      if (watchProjectHasItems) {
        tasksListRef.current?.removeAllTasksItems();
      }
    },
    [setOwnerId, setPaymentMethod, setTerms, watchProjectHasItems]
  );

  const { selectedBillingInfo } = useCreateProjectInTeamFormInvoiceDetails();

  const prevPaymentMethod = usePreviousValue(
    selectedBillingInfo?.avBillingInfo?.paymentMethod
  );

  useEffect(() => {
    if (
      selectedBillingInfo?.avBillingInfo?.paymentMethod !== prevPaymentMethod
    ) {
      setCurrency(
        selectedBillingInfo?.avBillingInfo?.paymentMethod !==
          AvBillingInfoPaymentMethods.WIRE
          ? Currencies.USD
          : companyCurrency || Currencies.USD
      );
    }
  }, [
    companyCurrency,
    prevPaymentMethod,
    selectedBillingInfo?.avBillingInfo?.paymentMethod,
    setCurrency
  ]);

  const paymentMethodIsWire =
    selectedBillingInfo?.avBillingInfo?.paymentMethod ==
    AvBillingInfoPaymentMethods.WIRE;

  return (
    <Form
      id={CREATE_PROJECT_IN_TEAM_FORM}
      className="relative max-w-4xl mx-auto"
      onSubmit={handleCreateOnlyProject}
    >
      <div className="p-4 mt-4">
        <h1 className="text-xl font-semibold text-center">
          <Translate id={projectsKeys.create} />
        </h1>
      </div>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div>
            <CheckPermissions
              action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TEAM_ID}
            >
              <TeamNanoIdSelectField<CreateProjectInTeamFormData>
                afterChange={handleTeamNanoIdAfterChange}
                control={control}
                disabled={
                  !currentUser.hasPermissions(
                    ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_TEAM_ID
                  ) || createProjectInTeamLoading
                }
                errorMessage={validationErrors.teamNanoIdValidationError}
                i18nConfirmationText={
                  stringsKeys.youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed
                }
                i18nLabel={words.client}
                i18nPlaceholder={teamsKeys.selectCompany}
                initialFilters={initialTeamsFilters}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={CreateProjectInTeamFormFields.TEAM_NANO_ID}
                withChangeConfirmation={watchProjectHasItems}
              />
            </CheckPermissions>

            <CheckPermissions
              action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_OWNER_ID}
            >
              <TeamsUserIdSelectField<CreateProjectInTeamFormData>
                cacheKey={TeamsUserCache.teamTeamsUsersCacheKey(
                  selectedTeamNanoId
                )}
                control={control}
                disabled={!selectedTeamNanoId}
                i18nPlaceholder={usersKeys.select}
                inputWrapperClassName={cl('relative mt-1', blockedStyles)}
                name={CreateProjectInTeamFormFields.OWNER_ID}
                teamNanoId={selectedTeamNanoId}
              />
            </CheckPermissions>
          </div>

          <CheckPermissions
            action={
              ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_GENERAL_LEDGER_ID
            }
          >
            <div className={cl(blockedStyles)}>
              <GeneralLedgersSelectField
                disabled={
                  !isTeamSelected ||
                  !currentUser.hasPermissions(
                    ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_GENERAL_LEDGER_ID
                  )
                }
                companyNanoId={selectedTeamNanoId}
                control={control}
                name={CreateProjectInTeamFormFields.GENERAL_LEDGER_ID}
                i18nLabel={invoicesKeys.selectPayerSubbook}
                i18nPlaceholder={invoicesKeys.selectPayerSubbook}
              />
            </div>
          </CheckPermissions>

          <div className={cl('flex flex-col sm:flex-row gap-4', blockedStyles)}>
            <div className="flex-1 flex flex-col gap-3">
              <InputField
                disabled={createProjectInTeamLoading}
                error={validationErrors.nameValidationError}
                i18nLabel={projectsKeys.name}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                name={registerName.name}
                onChange={registerName.onChange}
                ref={registerName.ref}
                type="text"
              />

              <CheckPermissions
                action={
                  ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_IMPLEMENTATION_DATE
                }
              >
                <CreateProjectInTeamFormImplementationDateField />
              </CheckPermissions>
            </div>

            <CheckPermissions
              action={
                ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_DESCRIPTION
              }
            >
              <div className="flex-1">
                <TextAreaField
                  disabled={createProjectInTeamLoading}
                  i18nLabel={formsFields.description}
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  textAreaWrapperClassName="mt-1"
                  name={registerDescription.name}
                  onChange={registerDescription.onChange}
                  ref={registerDescription.ref}
                  rows={5}
                />
              </div>
            </CheckPermissions>
          </div>
        </div>
      </div>

      {paymentMethodIsWire && (
        <CheckPermissions
          action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_CURRENCY}
        >
          <div
            className={cl('flex gap-x-2 items-center px-4 pt-4', blockedStyles)}
          >
            <div className="text-sm font-semibold">
              <Translate id={formsFields.currency} />:
            </div>

            <CurrencySelectField<CreateProjectInTeamFormData>
              inputWrapperClassName="h-full border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
              classNamePrefix="av_select"
              control={control}
              name={CreateProjectInTeamFormFields.PREFERRED_CURRENCY}
              onChange={handleChangeCurrency}
              disabled={!paymentMethodIsWire}
            />
            <ExchangeRatesListTooltipIconButton
              currencies={baseCurrenciesList}
            />
          </div>
        </CheckPermissions>
      )}
      <CheckPermissions
        action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_TASKS}
      >
        <div className={cl('p-4 space-y-4', blockedStyles)}>
          <CreateProjectInTeamFormTasksList
            disabled={createProjectInTeamLoading}
            ref={tasksListRef}
            currencyExchangeRate={currencyExchangeRate}
            currencyPrefix={currencyPrefix}
          />
        </div>
      </CheckPermissions>

      <CheckPermissions
        action={TasksPermissions.READ_TASK_CREATE_TASK_ITEMS_FORM_SPLIT_PARTS}
      >
        <div className={cl('p-4', blockedStyles)}>
          <CreateProjectInTeamFormSplitPartsInput
            companyNanoId={selectedTeamNanoId}
            control={control}
            register={register}
            setFocusSplitPart={setFocusSplitPart}
            setSplitPartValue={setSplitPartValue}
            watchSplitParts={watchSplitParts}
          />
        </div>
      </CheckPermissions>

      <CheckPermissions
        action={
          ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_FILE_ATTACHMENT_IDS
        }
      >
        <div className={cl('p-4', blockedStyles)}>
          <div className="space-y-4">
            <div>
              <div className="text-sm font-medium">
                <Translate id={attachmentsKeys.plural} />
              </div>
              <div className="text-xs text-gray-500">
                <Translate
                  id={
                    projectsKeys.youCanAddFilesRelatedToTheProjectSuchAsDrawingsTexturesReferencesModelsEtc
                  }
                />
              </div>
            </div>
            <div>
              <DropzoneField
                control={control}
                disabled={createProjectInTeamLoading}
                key={formNanoId}
                name={CreateProjectInTeamFormFields.FILE_ATTACHMENT_IDS}
                type="fileAttachments"
                withoutTabs
              />
            </div>
          </div>
        </div>
      </CheckPermissions>
      {/* Invoice Details */}
      <CheckPermissions
        action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_INVOICE_DETAILS}
      >
        <div className={cl('p-4', blockedStyles)}>
          <CreateProjectInTeamFormInvoiceDetails />
        </div>
      </CheckPermissions>
      {/* Clear, Create project only, Create buttons */}
      <div className="z-5 p-4 sticky bottom-0 bg-white dark:bg-gray-900 border-t dark:border-gray-800 mt-4">
        <CreateProjectInTeamFormButtons />
      </div>
    </Form>
  );
}

export default CreateProjectInTeamForm;
