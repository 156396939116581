import { Control, UseFormRegister } from 'react-hook-form';
import map from 'lodash/map';

import {
  CreateProjectInTeamFormData,
  CreateProjectInTeamFormFields,
  CreateProjectInTeamsSplitPart
} from '../../CreateProjectInTeamForm.types';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import { useCreateProjectInTeamFormSplitPartsInput } from './hooks/useCreateProjectInTeamFormSplitPartsInput';

import { CompanySmartContractSettingsDefaultSplitParts } from '../../../../../../companySmartContractSettings/helpers/CompanySmartContractSettingsDefaultSplitParts';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../../../helpers/Translate';
import { InputField } from '../../../../../../../helpers/FormFields/InputField';

import { ProjectsPermissions } from '../../../../../projectsConstants';
import { itemsKeys } from '../../../../../../../locales/keys';

interface CreateProjectInTeamFormSplitPartsInputProps {
  companyNanoId: TeamNanoID;
  control: Control<CreateProjectInTeamFormData>;
  register: UseFormRegister<CreateProjectInTeamFormData>;
  setFocusSplitPart: (index: number) => void;
  setSplitPartValue: (partIndex: number, partValue: number) => void;
  watchSplitParts: () => CreateProjectInTeamsSplitPart[];
}

function CreateProjectInTeamFormSplitPartsInput({
  companyNanoId,
  control,
  register,
  setFocusSplitPart,
  setSplitPartValue,
  watchSplitParts
}: CreateProjectInTeamFormSplitPartsInputProps) {
  const { fields, handleChange, placeholderIndex, placeholder } =
    useCreateProjectInTeamFormSplitPartsInput({
      control,
      watchSplitParts,
      setSplitPartValue,
      setFocusSplitPart
    });

  return (
    <div className="bg-gray-50 dark:bg-gray-800 rounded-lg text-sm px-4 py-2">
      <div className="flex gap-3">
        <label htmlFor="parts-custom">
          <Translate id={itemsKeys.splitParts} />:
        </label>
        <div className="flex gap-2">
          {map(fields, (field, index) => {
            const registerInput = register(
              `${CreateProjectInTeamFormFields.SPLIT_PARTS}.${index}.partValue`
            );

            return (
              <InputField
                key={field.id}
                inputClassName="text-center number-appearance-none dark:bg-transparent dark:text-gray-200 border-0 border-b border-gray-300 dark:border-gray-700 p-0 w-8 inline-flex focus:ring-0 focus:border-blue-500 text-sm"
                name={registerInput.name}
                onChange={registerInput.onChange}
                ref={registerInput.ref}
                onBlur={async (e) => {
                  await registerInput.onBlur(e);
                  handleChange();
                }}
                placeholder={
                  index === placeholderIndex.current
                    ? String(placeholder)
                    : undefined
                }
                type="number"
                min={0}
                max={100}
                step={1}
              />
            );
          })}

          <span className="text-gray-500">%</span>
        </div>
      </div>

      {!!companyNanoId && (
        <CheckPermissions
          action={
            ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_DEFAULT_SPLIT_PARTS
          }
        >
          <CompanySmartContractSettingsDefaultSplitParts
            companyNanoId={companyNanoId}
          />
        </CheckPermissions>
      )}

      <p className="text-gray-600 dark:text-gray-400 mt-2">
        <Translate
          id={
            itemsKeys.eachItemWillHaveASetNumberOfRegularInvoicesPricedProportionally
          }
        />
      </p>
    </div>
  );
}

export default CreateProjectInTeamFormSplitPartsInput;
