import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TeamNanoID } from '../../../teams/teamsTypes';

import { usePaginatedCompanySmartContractSettings } from '../../hooks/usePaginatedCompanySmartContractSettings';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import {
  FETCH_COMPANY_SMART_CONTRACT_SETTINGS_QUERY,
  FetchCompanySmartContractSettingsQueryResponse
} from '../../queries/fetchCompanySmartContractSettings.query';

import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../helpers/Translate';

import { CompanySmartContractSettingCache } from '../../CompanySmartContractSettingCache';

import { itemsKeys } from '../../../../locales/keys';

interface CompanySmartContractSettingsDefaultSplitPartsProps {
  companyNanoId: TeamNanoID;
}

function CompanySmartContractSettingsDefaultSplitParts({
  companyNanoId
}: CompanySmartContractSettingsDefaultSplitPartsProps) {
  const {
    companySmartContractSettings,
    companySmartContractSettingsFetched,
    changeCompanySmartContractSettingsFilters
  } = usePaginatedCompanySmartContractSettings<FetchCompanySmartContractSettingsQueryResponse>(
    {
      cacheKey:
        CompanySmartContractSettingCache.companyCompanySmartContractSettings(
          companyNanoId
        ),
      query: FETCH_COMPANY_SMART_CONTRACT_SETTINGS_QUERY,
      initialFilters: {
        companyNanoId: { eq: companyNanoId }
      },
      initialLimit: 1,
      options: { withoutPrefetch: true }
    }
  );

  const prevCompanyNanoId = usePreviousValue(companyNanoId);

  useEffect(() => {
    if (prevCompanyNanoId !== companyNanoId) {
      changeCompanySmartContractSettingsFilters({
        companyNanoId: { eq: companyNanoId }
      });
    }
  }, [
    companyNanoId,
    prevCompanyNanoId,
    changeCompanySmartContractSettingsFilters
  ]);

  if (
    companySmartContractSettingsFetched &&
    isEmpty(companySmartContractSettings)
  ) {
    return null;
  }

  return (
    <LoadingSkeleton withoutLoader loaded={companySmartContractSettingsFetched}>
      <div className="flex gap-3 mt-2">
        <div>
          <Translate id={itemsKeys.defaultSplitParts} />:
        </div>
        <span className="text-gray-500">
          {companySmartContractSettings?.[0]?.splitPartPercents?.join(' / ')} %
        </span>
      </div>
    </LoadingSkeleton>
  );
}

export default CompanySmartContractSettingsDefaultSplitParts;
