import { FetchCustomFieldsSortTypes } from '../customFields/customFieldsTypes';

export const INITIAL_COMPANY_SMART_CONTRACT_SETTING_FILTERS = {};
export const INITIAL_COMPANY_SMART_CONTRACT_SETTING_LIMIT = 1000;
export const INITIAL_COMPANY_SMART_CONTRACT_SETTING_PAGE = 1;
export const INITIAL_COMPANY_SMART_CONTRACT_SETTING_SORT = [
  FetchCustomFieldsSortTypes.CREATED_AT_ASC
];

export const enum CompanySmartContractSettingsPermissions {
  READ_COMPANY_SMART_CONTRACT_SETTINGS_SPLIT_PART_PERCENTS_FIELD = 'read_company_smart_contract_settings_split_part_percents_field',
  CHANGE_COMPANY_SMART_CONTRACT_SETTINGS_SPLIT_PART_PERCENTS_FIELD = 'change_company_smart_contract_settings_split_part_percents_field'
}
